<template>
  <el-container class="content-center-middle">
    <el-card>
      <el-row class="content-center-middle" :gutter="10">
        <el-col :span="24">
          <div class="error">
            <el-icon>
              <i class="bi bi-x-circle"></i>
            </el-icon>
          </div>
          <p>{{ message }}</p>
        </el-col>
      </el-row>
      <!-- <p v-text="$t('personal.complete.appNo', {appNo: appNo})" /> -->
      
    </el-card>
  </el-container>
</template>

<style scoped>
ul.buttons {
  background-color: #ffffff;
}
.content-center-middle {
  display: flex !important;
}
@media screen and (max-width: 576px) {
  .image {
    display: none;
  }
  .el-card{
    text-align: center;
    font-size: 18px;
  }
}
@media screen and (min-width: 577px) {
  .el-card {
    text-align: center;
    font-size: 20px;
  }
}

.error .el-icon {
  font-size: 5em;
  color: #dc3545;
}
.el-container {
  display: block;
}
.el-card {
  width: 90%;
}
.image {
  width: 100%;
}
@media screen and (max-width: 709px) {
  .el-container {
    min-height: calc(100vh - 137px);
  }
}

@media screen and (min-width: 710px) {
  .el-container {
    min-height: calc(100vh - 120px);
  }
}

</style>

<script>
import "@/style/common.css"; 

export default {
  name: "unauthorized",
  data() {
    return {
      type: null
    }
  },
  computed: {
    message() {
      return this.type ? this.$i18n.t(`personal.unauthorized.${this.type}`) : ""
    }
  },
  beforeMount() {
    this.type = window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? "exception" : "default"
  }
}
</script>